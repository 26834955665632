// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page-deco {
  background: linear-gradient(to bottom, #ff8d1d 365px, #ffffff 0%);
}

.coverflow {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin: 35% 0% -44% 0%;
  color: #ff8d1d;
}

.main-info {
  text-align: center;
  margin-top: 22%;
  font-size: 2.8rem;
  letter-spacing: -1.5px;
  color: #000;
  font-weight: 500;
  line-height: 1.3;
}

.main-info-bold {
  font-family: "pretendard-extraBold";
}

.white-bold {
  font-family: "pretendard-extraBold";
  color: #ffffff;
}

.main-logo {
  margin: 40% 0% -44% 24%;
}

.search-input-text {
  letter-spacing: -1px;
}

.search {
  margin: 0 0 -1% -7%;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/mainPage/mainPage.scss"],"names":[],"mappings":"AAAA;EAEE,iEAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;AAAF;;AAGA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,mCAAA;AAAF;;AAGA;EACE,mCAAA;EACA,cAAA;AAAF;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,eAAA;AAAF","sourcesContent":[".main-page-deco {\n  // background: linear-gradient(to bottom, #ffffff, #fff3e7);\n  background: linear-gradient(to bottom, #ff8d1d 365px, #ffffff 0%);\n}\n\n.coverflow {\n  font-size: 2rem;\n  font-weight: 800;\n  text-align: center;\n  margin: 35% 0% -44% 0%;\n  color: #ff8d1d;\n}\n\n.main-info {\n  text-align: center;\n  margin-top: 22%;\n  font-size: 2.8rem;\n  letter-spacing: -1.5px;\n  color: #000;\n  font-weight: 500;\n  line-height: 1.3;\n}\n\n.main-info-bold {\n  font-family: 'pretendard-extraBold';\n}\n\n.white-bold {\n  font-family: 'pretendard-extraBold';\n  color: #ffffff;\n}\n\n.main-logo {\n  margin: 40% 0% -44% 24%;\n}\n\n.search-input-text {\n  letter-spacing: -1px;\n}\n\n.search {\n  margin: 0 0 -1% -7%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
