// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea-field-contact,
.option-field {
  letter-spacing: -1px;
  font-size: 1.5rem;
  border: none;
  margin: 20px 0;
  width: 520px;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
  padding: 2rem;
  height: 230px;
  resize: none;
}
.textarea-field-contact::placeholder,
.option-field::placeholder {
  color: #cacaca;
  opacity: 1;
}

.round {
  border-radius: 1rem;
  height: 15rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/inputBox/textArea.scss"],"names":[],"mappings":"AAAA;;EAEE,oBAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;EACA,iCAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;AACF;AACE;;EACE,cAAA;EACA,UAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,aAAA;AACF","sourcesContent":[".textarea-field-contact,\n.option-field {\n  letter-spacing: -1px;\n  font-size: 1.5rem;\n  border: none;\n  margin: 20px 0;\n  width: 520px;\n  background-color: #f5f5f5;\n  transition: background-color 0.3s;\n  padding: 2rem;\n  height: 230px;\n  resize: none;\n\n  &::placeholder {\n    color: #cacaca;\n    opacity: 1;\n  }\n}\n\n.round {\n  border-radius: 1rem;\n  height: 15rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
