// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-wrapper {
  width: 100%;
  height: 100%;
  padding: 3rem 0;
}

.no-fish-purchase {
  width: 100%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fish-disclaimer {
  font-family: "Pretendard-bold";
  font-size: 3rem;
  color: black;
  padding-top: 1rem;
  letter-spacing: -1px;
}

.fish-purchase-item {
  width: 100%;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.1);
  height: 9rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 3% 0;
}

.fishbun-cnt {
  font-family: pretendard-bold;
  font-size: 2rem;
  padding-bottom: 0.8rem;
  letter-spacing: -1px;
}

.fishbun-price {
  font-family: pretendard-SemiBold;
  font-size: 1.6rem;
  letter-spacing: -1px;
  color: #474646;
}
.fishbun-price span {
  font-family: pretendard-medium;
  color: #808080;
  padding-left: 0.5rem;
}

.store-container {
  padding-top: 3rem;
  margin: 0px auto;
  width: 540px;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/storePage/storePage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,8BAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,8CAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,4BAAA;EACA,eAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AAEA;EACE,gCAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;AACF;AAAE;EACE,8BAAA;EACA,cAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".store-wrapper {\n  width: 100%;\n  height: 100%;\n  padding: 3rem 0;\n}\n\n.no-fish-purchase {\n  width: 100%;\n  height: 30rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.fish-disclaimer {\n  font-family: 'Pretendard-bold';\n  font-size: 3rem;\n  color: black;\n  padding-top: 1rem;\n  letter-spacing: -1px;\n}\n\n.fish-purchase-item {\n  width: 100%;\n  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.1);\n  height: 9rem;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  margin: 3% 0;\n}\n\n.fishbun-cnt {\n  font-family: pretendard-bold;\n  font-size: 2rem;\n  padding-bottom: 0.8rem;\n  letter-spacing: -1px;\n}\n\n.fishbun-price {\n  font-family: pretendard-SemiBold;\n  font-size: 1.6rem;\n  letter-spacing: -1px;\n  color: #474646;\n  span {\n    font-family: pretendard-medium;\n    color: #808080;\n    padding-left: 0.5rem;\n  }\n}\n\n.store-container {\n  padding-top: 3rem;\n  margin: 0px auto;\n  width: 540px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
