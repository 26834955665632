// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.title-login {
  margin-top: 10%;
}

hr {
  border: none;
  border-top: 1px solid #d9d9d9;
  text-align: center;
  overflow: visible;
  width: 75%;
}

hr:after {
  content: "또는";
  font-size: 0.9rem;
  position: relative;
  top: -10px;
  background-color: white;
  color: #474646;
  letter-spacing: -1px;
  padding: 0 10px;
}

.kakao-login {
  margin: 0% 0% 4% 12%;
  width: 75%;
}

.login-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/loginPage/loginPage.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,eAAA;AAEF;;AACA;EACE,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,iBAAA;EACA,UAAA;AAEF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,uBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;AAEF;;AACA;EACE,oBAAA;EACA,UAAA;AAEF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF","sourcesContent":[".title-login {\n  margin-top: 10%;\n}\n\nhr {\n  border: none;\n  border-top: 1px solid #d9d9d9;\n  text-align: center;\n  overflow: visible;\n  width: 75%;\n}\n\nhr:after {\n  content: '또는';\n  font-size: 0.9rem;\n  position: relative;\n  top: -10px;\n  background-color: white;\n  color: #474646;\n  letter-spacing: -1px;\n  padding: 0 10px;\n}\n\n.kakao-login {\n  margin: 0% 0% 4% 12%;\n  width: 75%;\n}\n\n.login-buttons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
