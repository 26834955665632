// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.button-container {
  display: flex;
  flex-wrap: wrap;
  width: 15rem;
}

.gender-button-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
}

.gender-button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin: 5% 10px 0% 0%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* 트랜지션 효과 추가 */
}

.gender-button.selected {
  background-color: #ff8d1d;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.gender-button:not(.selected) {
  background-color: #cacaca;
  color: white;
}

.gender-button:hover {
  background-color: #ff8d1d;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/genderSelection/genderSelection.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,eAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;EACA,eAAA;EACA,cAAA;AAEF;;AACA;EACE,iBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,0CAAA;EACA,8DACE,EAEiB,eAAA;AADrB;;AAIA;EACE,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,0CAAA;AADF;;AAIA;EACE,yBAAA;EACA,YAAA;AADF;;AAIA;EACE,yBAAA;EACA,0CAAA;AADF","sourcesContent":[".button-container {\n  display: flex;\n  flex-wrap: wrap;\n  width: 15rem;\n}\n\n.gender-button-container {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 5%;\n}\n\n.gender-button {\n  padding: 8px 16px;\n  border: none;\n  cursor: pointer;\n  border-radius: 10px;\n  margin: 5% 10px 0% 0%;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);\n  transition:\n    background-color 0.3s,\n    color 0.3s,\n    box-shadow 0.3s; /* 트랜지션 효과 추가 */\n}\n\n.gender-button.selected {\n  background-color: #ff8d1d;\n  color: #ffffff;\n  font-weight: bold;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);\n}\n\n.gender-button:not(.selected) {\n  background-color: #cacaca;\n  color: white;\n}\n\n.gender-button:hover {\n  background-color: #ff8d1d;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
