// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../image/admin-toggle.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secession-reason-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5% 0% 10% 0%;
}

.secession-reason-label {
  margin-bottom: 10px;
  font-weight: bold;
}

.secession-reason-select {
  border: 1px solid #474646;
  outline: none;
  background-color: transparent;
  position: relative;
  padding: 10px;
  letter-spacing: -1px;
  width: 470px;
  height: 45px;
  border-radius: 20px;
  font-size: 1.5rem;
  color: #474646;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right 20px center;
  appearance: none;
}

.secession-reason-select:focus {
  border-color: #ff8d1d;
}

.secession-reason-select::-ms-expand {
  display: none;
}

.secession-reason-dropdown:after {
  pointer-events: none;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/selection/secessionSelection.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,6BAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,yDAAA;EACA,4BAAA;EACA,sCAAA;EAGA,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,oBAAA;EACA,WAAA;AACF","sourcesContent":[".secession-reason-dropdown {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin: 5% 0% 10% 0%;\n}\n\n.secession-reason-label {\n  margin-bottom: 10px;\n  font-weight: bold;\n}\n\n.secession-reason-select {\n  border: 1px solid #474646;\n  outline: none;\n  background-color: transparent;\n  position: relative;\n  padding: 10px;\n  letter-spacing: -1px;\n  width: 470px;\n  height: 45px;\n  border-radius: 20px;\n  font-size: 1.5rem;\n  color: #474646;\n  background-image: url('../../../image/admin-toggle.svg');\n  background-repeat: no-repeat;\n  background-position: right 20px center;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n}\n\n.secession-reason-select:focus {\n  border-color: #ff8d1d;\n}\n\n.secession-reason-select::-ms-expand {\n  display: none;\n}\n\n.secession-reason-dropdown:after {\n  pointer-events: none;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
