// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-count {
  color: #ff8d1d;
  margin-left: 1.5%;
}

.divider {
  color: #000;
}

.result-data-failed {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  letter-spacing: -1px;
  align-items: center;
}

.warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8.6rem;
  margin-top: 13%;
  margin-bottom: 5%;
}

.failed-text {
  font-family: pretendard-bold;
  color: #000000;
  font-size: 4rem;
  margin-bottom: 1%;
  letter-spacing: -1px;
}

.failed-text2 {
  font-family: pretendard-light;
  color: #808080;
  font-size: 2rem;
  margin-bottom: 5%;
  letter-spacing: -1px;
}

.registContainer {
  display: flex;
  justify-content: center;
  border: 1px solid #ff8d1d;
  width: 20rem;
  border-radius: 3rem;
  height: 4.5rem;
  align-items: center;
}

.plus-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.company-registration {
  display: flex;
  color: #ff8d1d;
  font-size: 2rem;
  letter-spacing: -1px;
  font-family: pretendard-medium;
}

.pagination-button-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/searchPage/searchResultPage.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,4BAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,6BAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,oBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,6BAAA;EACA,gBAAA;AACF","sourcesContent":[".result-count {\n  color: #ff8d1d;\n  margin-left: 1.5%;\n}\n\n.divider {\n  color: #000;\n}\n\n.result-data-failed {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  letter-spacing: -1px;\n  align-items: center;\n}\n\n.warning-icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 8rem;\n  width: 8.6rem;\n  margin-top: 13%;\n  margin-bottom: 5%;\n}\n\n.failed-text {\n  font-family: pretendard-bold;\n  color: #000000;\n  font-size: 4rem;\n  margin-bottom: 1%;\n  letter-spacing: -1px;\n}\n\n.failed-text2 {\n  font-family: pretendard-light;\n  color: #808080;\n  font-size: 2rem;\n  margin-bottom: 5%;\n  letter-spacing: -1px;\n}\n\n.registContainer {\n  display: flex;\n  justify-content: center;\n  border: 1px solid #ff8d1d;\n  width: 20rem;\n  border-radius: 3rem;\n  height: 4.5rem;\n  align-items: center;\n}\n\n.plus-icon {\n  width: 1.5rem;\n  height: 1.5rem;\n  margin-right: 0.5rem;\n}\n\n.company-registration {\n  display: flex;\n  color: #ff8d1d;\n  font-size: 2rem;\n  letter-spacing: -1px;\n  font-family: pretendard-medium;\n}\n\n.pagination-button-container {\n  display: flex;\n  justify-content: space-evenly;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
