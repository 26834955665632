// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: relative;
  display: flex;
  align-items: center;
}

img.hambar {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 14.5%;
  margin-top: -3.5%;
  cursor: pointer;
  z-index: 1;
}

.sidebar-wrapper {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/header/header.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".header {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\nimg.hambar {\n  position: relative;\n  display: flex;\n  align-items: center;\n  margin-left: 14.5%;\n  margin-top: -3.5%;\n  cursor: pointer;\n  z-index: 1;\n}\n\n.sidebar-wrapper {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
