// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.back {
  margin-right: 30%;
  margin-top: 0.8%;
}

span.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/header/titleHeader.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AACF","sourcesContent":["img.back {\n  margin-right: 30%;\n  margin-top: 0.8%;\n}\n\nspan.page-title {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
