// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-list {
  margin-top: 5%;
}

.no-read {
  letter-spacing: -1px;
  font-weight: 800;
  font-size: 2rem;
  display: flex;
  gap: 5px;
  margin-top: 12%;
  margin-left: 10%;
}

.no-read-count {
  color: #ff8d1d;
}

.all-read-btn {
  letter-spacing: -0.5px;
  border-radius: 5px;
  margin-top: 11.5%;
  margin-right: 8%;
  border: 1px solid #000;
  width: 7.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  font-family: "pretendard-extrabold";
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notification-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/notificationPage/notificationPage.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,cAAA;EACA,iBAAA;EACA,mCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourcesContent":[".notification-list {\n  margin-top: 5%;\n}\n\n.no-read {\n  letter-spacing: -1px;\n  font-weight: 800;\n  font-size: 2rem;\n  display: flex;\n  gap: 5px;\n  margin-top: 12%;\n  margin-left: 10%;\n}\n\n.no-read-count {\n  color: #ff8d1d;\n}\n\n.all-read-btn {\n  letter-spacing: -0.5px;\n  border-radius: 5px;\n  margin-top: 11.5%;\n  margin-right: 8%;\n  border: 1px solid #000;\n  width: 7.5rem;\n  height: 2.5rem;\n  font-size: 1.2rem;\n  font-family: 'pretendard-extrabold';\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor : pointer;\n}\n\n.notification-controls {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
