// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-container {
  position: absolute;
  right: 4rem;
  padding: 0;
  top: 29%;
}

.premium-banner {
  width: 100%;
}

.banner-text span {
  font-size: 2.5rem;
  color: #000000;
  letter-spacing: -1px;
  font-family: Pretendard-ExtraBold;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/banner/bannerImage.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,QAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAGE;EACE,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,iCAAA;AAAJ","sourcesContent":[".img-container {\n  position: absolute;\n  right: 4rem;\n  padding: 0;\n  top: 29%;\n}\n\n.premium-banner {\n  width: 100%;\n}\n\n.banner-text {\n  span {\n    font-size: 2.5rem;\n    color: #000000;\n    letter-spacing: -1px;\n    font-family: Pretendard-ExtraBold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
