// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-fish-container {
  width: 540px;
  border-radius: 0.3rem;
  background-color: rgb(255, 249, 244);
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fish-bread-title {
  padding-left: 1rem;
  font-size: 1.5rem;
  font-family: pretendard-bold;
  letter-spacing: -1px;
}

.fish-bread-title-orange {
  padding-left: 0.6rem;
  font-size: 1.5rem;
  font-family: pretendard-bold;
  color: rgb(255, 141, 29);
  letter-spacing: -1px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/banner/currentFishBanner.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,qBAAA;EACA,oCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,4BAAA;EACA,oBAAA;AACF;;AAEA;EACE,oBAAA;EACA,iBAAA;EACA,4BAAA;EACA,wBAAA;EACA,oBAAA;AACF","sourcesContent":[".current-fish-container {\n  width: 540px;\n  border-radius: 0.3rem;\n  background-color: rgba(255, 249, 244, 1);\n  height: 5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.fish-bread-title {\n  padding-left: 1rem;\n  font-size: 1.5rem;\n  font-family: pretendard-bold;\n  letter-spacing: -1px;\n}\n\n.fish-bread-title-orange {\n  padding-left: 0.6rem;\n  font-size: 1.5rem;\n  font-family: pretendard-bold;\n  color: rgba(255, 141, 29, 1);\n  letter-spacing: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
