// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-container {
  width: 80%;
  height: auto;
  margin: 20% 10%;
}

.feedback-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 2rem;
}

.feedback-title {
  font-family: pretendard-bold;
  font-size: 3.5rem;
  padding-bottom: 0.2rem;
  letter-spacing: -1px;
}

.feedback-item-container {
  width: 100%;
}

.feedback-content {
  letter-spacing: -1px;
  font-size: 2rem;
  font-family: Pretendard-Light;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/myPage/feedbackPage.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,YAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,oBAAA;AAEF;;AACA;EACE,4BAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,oBAAA;EACA,eAAA;EACA,6BAAA;EACA,gBAAA;AAEF","sourcesContent":[".feedback-container {\n  width: 80%;\n  height: auto;\n  margin: 20% 10%;\n}\n.feedback-title-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  padding-bottom: 2rem;\n}\n\n.feedback-title {\n  font-family: pretendard-bold;\n  font-size: 3.5rem;\n  padding-bottom: 0.2rem;\n  letter-spacing: -1px;\n}\n\n.feedback-item-container {\n  width: 100%;\n}\n\n.feedback-content {\n  letter-spacing: -1px;\n  font-size: 2rem;\n  font-family: Pretendard-Light;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
