// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-contents-wrapper {
  width: 550px;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5rem 0 20rem 0;
}

.ask-question {
  font-size: 1.5rem;
  color: #ff8d1d;
  font-family: Pretendard-Medium;
  letter-spacing: -1px;
}

.no-component-plus {
  width: 11px;
  height: 11px;
  margin-right: 1rem;
}

.no-component-plus-container {
  width: 200px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #ff8d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.no-component-diclaimer {
  font-size: 2rem;
  color: #808080;
  font-family: Pretendard-Medium;
  letter-spacing: -1px;
}

.no-component-title {
  text-align: center;
  font-size: 3rem;
  color: #474646;
  font-family: Pretendard-Bold;
  letter-spacing: -1px;
}

.my-component-css {
  margin-bottom: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/noContentsComponent/noContentsComponents.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AACA;EACE,iBAAA;EACA,cAAA;EACA,8BAAA;EACA,oBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AACA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;EACA,cAAA;EACA,8BAAA;EACA,oBAAA;AAEF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;EACA,4BAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".no-contents-wrapper {\n  width: 550px;\n  height: 25rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  margin: 5rem 0 20rem 0;\n}\n.ask-question {\n  font-size: 1.5rem;\n  color: #ff8d1d;\n  font-family: Pretendard-Medium;\n  letter-spacing: -1px;\n}\n\n.no-component-plus {\n  width: 11px;\n  height: 11px;\n  margin-right: 1rem;\n}\n\n.no-component-plus-container {\n  width: 200px;\n  height: 30px;\n  border-radius: 20px;\n  border: 1px solid #ff8d1d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.no-component-diclaimer {\n  font-size: 2rem;\n  color: #808080;\n  font-family: Pretendard-Medium;\n  letter-spacing: -1px;\n  \n}\n\n.no-component-title {\n  text-align: center;\n  font-size: 3rem;\n  color: #474646;\n  font-family: Pretendard-Bold;\n  letter-spacing: -1px;\n}\n\n.my-component-css{\n  margin-bottom:6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
