// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nickname {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-top: 10%;
  letter-spacing: -1px;
  font-weight: 600;
}

.back {
  margin-left: -30%;
  margin-right: 27%;
  cursor: pointer;
}

.nickname-info {
  margin: 12% 0% 2.5% 10%;
  font-size: 3.5rem;
  letter-spacing: -1px;
  color: #000;
  font-family: "pretendard-Bold";
  line-height: 1.2;
}

.separator {
  width: 88%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 5% -1.5% -5% 0%;
}

.neccessary {
  font-size: 14px;
  color: #d50202;
  display: inline-block;
  vertical-align: middle;
  margin: -1% 0% 0% 2%;
}

.select {
  font-size: 14px;
  color: #22932d;
  display: inline-block;
  vertical-align: middle;
  margin: -1% 0% 0% 2%;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/loginPage/nicknamePage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,oBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,iBAAA;EACA,oBAAA;EACA,WAAA;EACA,8BAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,yBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;AACF","sourcesContent":[".nickname {\n  display: flex;\n  justify-content: center;\n  font-size: 1rem;\n  margin-top: 10%;\n  letter-spacing: -1px;\n  font-weight: 600;\n}\n\n.back {\n  margin-left: -30%;\n  margin-right: 27%;\n  cursor: pointer;\n}\n\n.nickname-info {\n  margin: 12% 0% 2.5% 10%;\n  font-size: 3.5rem;\n  letter-spacing: -1px;\n  color: #000;\n  font-family: 'pretendard-Bold';\n  line-height: 1.2;\n}\n\n.separator {\n  width: 88%;\n  height: 1px;\n  background-color: #d9d9d9;\n  margin: 5% -1.5% -5% 0%;\n}\n\n.neccessary {\n  font-size: 14px;\n  color: #d50202;\n  display: inline-block;\n  vertical-align: middle;\n  margin: -1% 0% 0% 2%;\n}\n\n.select {\n  font-size: 14px;\n  color: #22932d;\n  display: inline-block;\n  vertical-align: middle;\n  margin: -1% 0% 0% 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
