// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.age-button-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
  width: 45%;
}

button {
  min-width: 8rem;
  font-size: 1.8rem;
  letter-spacing: -1px;
  color: #ffffff;
  font-family: Pretendard-Bold;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin: 5% 10px 0% 0%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* 트랜지션 효과 추가 */
}

button.selected {
  background-color: #ff8d1d;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

button:not(.selected) {
  background-color: #cacaca;
  font-weight: bold;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/ageSelection/ageSelection.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,UAAA;AAEF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;EACA,4BAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,0CAAA;EACA,8DACE,EAEiB,eAAA;AADrB;;AAIA;EACE,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,0CAAA;AADF;;AAIA;EACE,yBAAA;EACA,iBAAA;EACA,YAAA;AADF","sourcesContent":[".age-button-container {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 5%;\n  width: 45%;\n}\n\nbutton {\n  min-width: 8rem;\n  font-size: 1.8rem;\n  letter-spacing: -1px;\n  color: #ffffff;\n  font-family: Pretendard-Bold;\n  padding: 8px 16px;\n  border: none;\n  cursor: pointer;\n  border-radius: 10px;\n  margin: 5% 10px 0% 0%;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);\n  transition:\n    background-color 0.3s,\n    color 0.3s,\n    box-shadow 0.3s; /* 트랜지션 효과 추가 */\n}\n\nbutton.selected {\n  background-color: #ff8d1d;\n  color: #ffffff;\n  font-weight: bold;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);\n}\n\nbutton:not(.selected) {\n  background-color: #cacaca;\n  font-weight: bold;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
