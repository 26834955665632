// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fish-item-container {
  width: 100%;
  height: 5rem;
  padding: 1rem 0.6rem;
  border-bottom: 0.1rem rgba(217, 217, 217, 0.5) solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fish-bread-cnt {
  padding-left: 1.5rem;
  font-size: 1.8rem;
  font-family: pretendard-semibold;
  letter-spacing: -1px;
}

.fish-bread-cnt-orange {
  font-size: 1.3rem;
  font-family: pretendard-ExtraBold;
  color: rgb(255, 141, 29);
  letter-spacing: -1px;
  padding-top: 0.1rem;
}

.fish-count-wrapper {
  display: flex;
  align-items: center;
  padding-left: 5rem;
}

.fish-price-border {
  border: 0.12rem rgb(255, 141, 29) solid;
  width: 8rem;
  height: 2.5rem;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fish-list-container {
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/selection/fishListItem.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,oDAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,oBAAA;EACA,iBAAA;EACA,gCAAA;EACA,oBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iCAAA;EACA,wBAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,uCAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".fish-item-container {\n  width: 100%;\n  height: 5rem;\n  padding: 1rem 0.6rem;\n  border-bottom: 0.1rem rgba(217, 217, 217, 0.5) solid;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.fish-bread-cnt {\n  padding-left: 1.5rem;\n  font-size: 1.8rem;\n  font-family: pretendard-semibold;\n  letter-spacing: -1px;\n}\n\n.fish-bread-cnt-orange {\n  font-size: 1.3rem;\n  font-family: pretendard-ExtraBold;\n  color: rgba(255, 141, 29, 1);\n  letter-spacing: -1px;\n  padding-top: 0.1rem;\n}\n\n.fish-count-wrapper {\n  display: flex;\n  align-items: center;\n  padding-left: 5rem;\n}\n\n.fish-price-border {\n  border: 0.12rem rgba(255, 141, 29, 1) solid;\n  width: 8rem;\n  height: 2.5rem;\n  padding: 0.3rem 1rem;\n  border-radius: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.fish-list-container {\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
