// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-container {
  width: 100%;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.check-payment-icon {
  width: 5rem;
  padding-bottom: 1rem;
}

.check-payment-title {
  font-family: "pretendard-Bold";
  font-size: 3.5rem;
  padding-bottom: 0.3rem;
  letter-spacing: -1px;
}

.check-payment-content {
  font-family: "Pretendard-Light";
  font-size: 2.5rem;
  letter-spacing: -1px;
  padding-bottom: 0.2rem;
}

.payResult-container {
  margin-top: 4rem;
  width: 78%;
  border-top: 1px rgba(197, 197, 197, 0.551) solid;
  border-bottom: 1px rgba(197, 197, 197, 0.551) solid;
  padding: 1rem 0;
}

.payResult-item {
  font-family: pretendard-light;
  font-size: 2.5rem;
  color: #808080;
  letter-spacing: -1px;
}

.payResult-item-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/completePaymentPage/completePaymentPage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,WAAA;EACA,oBAAA;AAEF;;AACA;EACE,8BAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;AAEF;;AACA;EACE,+BAAA;EACA,iBAAA;EACA,oBAAA;EACA,sBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,UAAA;EACA,gDAAA;EACA,mDAAA;EACA,eAAA;AAGF;;AAAA;EACE,6BAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AAGF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAGF","sourcesContent":[".check-container {\n  width: 100%;\n  height: 50rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.check-payment-icon {\n  width: 5rem;\n  padding-bottom: 1rem;\n}\n\n.check-payment-title {\n  font-family: 'pretendard-Bold';\n  font-size: 3.5rem;\n  padding-bottom: 0.3rem;\n  letter-spacing: -1px;\n}\n\n.check-payment-content {\n  font-family: 'Pretendard-Light';\n  font-size: 2.5rem;\n  letter-spacing: -1px;\n  padding-bottom: 0.2rem;\n}\n.payResult-container {\n  margin-top: 4rem;\n  width: 78%;\n  border-top: 1px rgba(197, 197, 197, 0.551) solid;\n  border-bottom: 1px rgba(197, 197, 197, 0.551) solid;\n  padding: 1rem 0;\n}\n\n.payResult-item {\n  font-family: pretendard-light;\n  font-size: 2.5rem;\n  color: #808080;\n  letter-spacing: -1px;\n}\n\n.payResult-item-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
