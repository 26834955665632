// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page {
  width: 100%;
  height: 100%;
  display: flex;
}

.section {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/pages/adminPage/adminMainPage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;AACF;;AAIA;EACE,YAAA;AADF","sourcesContent":[".admin-page {\n  width: 100%;\n  height: 100%;\n  display: flex;\n\n  // justify-content: space-around;\n}\n\n.section {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
