// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 70rem;
  margin: 0 auto;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav > div {
  float: left;
  text-align: center;
  background-color: #fefefe;
  line-height: 45px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}

.icon {
  font-size: 1.3rem;
  margin-top: 10%;
}

.text {
  margin-top: -5%;
  margin-bottom: -8px;
  font-size: 1.2rem;
  letter-spacing: -1px;
}

.icon-overlay {
  position: absolute;
  top: 2.1rem;
  right: 10.6rem;
}

.nav-center {
  margin-left: 33.3%;
}`, "",{"version":3,"sources":["webpack://./src/asset/sass/etc/tabBar/tabBar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,uCAAA;EACA,uBAAA;EACA,YAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAMA;EACE,kBAAA;EACA,WAAA;EACA,cAAA;AAHF;;AAMA;EACE,kBAAA;AAHF","sourcesContent":[".wrapper {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 99;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  background-color: white;\n  width: 70rem;\n  margin: 0 auto;\n}\n\nnav {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\nnav > div {\n  float: left;\n  text-align: center;\n  background-color: #fefefe;\n  line-height: 45px;\n}\n\n.nav-item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: #000;\n}\n\n.icon {\n  font-size: 1.3rem;\n  margin-top: 10%;\n}\n\n.text {\n  margin-top: -5%;\n  margin-bottom: -8px;\n  font-size: 1.2rem;\n  letter-spacing: -1px;\n}\n\n// .tab-active {\n//   color: #ff8d1d;\n// }\n\n.icon-overlay {\n  position: absolute;\n  top: 2.1rem;\n  right: 10.6rem;\n}\n\n.nav-center {\n  margin-left: 33.3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
